import styled from "styled-components";
import * as Colors from "@constants/colors";
import { ClearfaceFont, TantinotesFont } from "@constants/fonts";
import { Screens } from "@constants/screens";
import { Quote } from "@components/shared.styled";

const Container = styled.section`
  background-color: ${({ backgroundColor = Colors.charcoal }) =>
    backgroundColor};
  padding: 112px 0 112px;
  color: ${Colors.offWhite};

  @media (max-width: ${Screens.lg}) {
    padding: 50px 0;
  }
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: ${Screens.lg}) {
    flex-direction: column;
    justify-content: flex-start;
  }
`;

const Title = styled.h2`
  margin: 0 0 0 8px;
  font-size: 56px;
  font-weight: 700;
  font-family: ${ClearfaceFont};
  color: ${Colors.white};
  line-height: 72px;
  max-width: 345px;

  @media (max-width: ${Screens.lg}) {
    font-size: 50px;
    line-height: 60px;
  }

  @media (max-width: ${Screens.md}) {
    font-size: 36px;
    line-height: 40px;
  }
`;

const InfoBlock = styled.div`
  max-width: 570px;
  margin-top: 230px;

  @media (max-width: ${Screens.lg}) {
    margin-top: 60px;
  }
`;

const Comment = styled.div`
  padding: 30px 0 5px 32px;
  border-left: 1px solid ${Colors.white};
  margin-bottom: 20px;

  @media (max-width: ${Screens.lg}) {
    padding-top: 0;
  }
`;

const StyledQuote = styled(Quote)`
  font-size: 200px;
  margin: 0 16px 0 0;
  line-height: 128px;
  color: ${Colors.white};

  @media (max-width: ${Screens.lg}) {
    font-size: 100px;
    line-height: 100px;
  }
`;

const CommentBody = styled.div`
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.42px;
  margin: 0 0 20px 0;

  @media (max-width: ${Screens.md}) {
    font-size: 18px;
    line-height: 26px;
  }
`;

const Sign = styled.p`
  font-family: ${TantinotesFont};
  font-size: 74px;
  line-height: 96px;
  color: ${Colors.seafoam};
  margin: 0;
  padding-left: 32px;

  @media (max-width: ${Screens.lg}) {
    font-size: 40px;
    line-height: 50px;
    margin-bottom: 10px;
  }
`;

export {
  Container,
  Content,
  Title,
  InfoBlock,
  Comment,
  StyledQuote,
  CommentBody,
  Sign,
};
