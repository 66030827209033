import styled from "styled-components";
import * as Colors from "@constants/colors";
import { Screens } from "@constants/screens";
import Image from "@components/image";
import { Wrapper } from "@components/shared.styled";

const StyledWrapper = styled(Wrapper)`
  max-width: 1195px;
`;

const Container = styled.div`
  background: ${({ backgroundColor = Colors.white }) => backgroundColor};
  padding: 112px 0 48px;
  text-align: center;

  @media (max-width: ${Screens.lg}) {
    padding: 50px 0 0;
  }
`;

const TeamContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 1235px) {
    justify-content: center;
  }
`;

const PersonalInfo = styled.div`
  background-color: ${Colors.charcoal};
  color: ${Colors.white};
  font-size: 18px;
  line-height: 26px;
  padding: 25px;
  flex-grow: 0;
  margin-top: auto;
`;

const PersonalName = styled.h4`
  margin: 0;
  font-weight: bold;
`;

const PersonalPosition = styled.p`
  margin: 0;
`;

const CommentContainer = styled.div`
  background-color: ${Colors.seafoamLite};
  padding: 64px 48px 0;
  text-align: left;
  margin-bottom: 10px;
`;

const QuotationMark = styled.p`
  font-size: 80px;
  line-height: 28px;
  margin: 0 0 10px;
`;

const Comment = styled.div`
  margin: 0;
  font-size: 18px;
  line-height: 24px;
`;

const TeamMember = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${Colors.white};
  transition: all 0.5s ease-out;
`;

const TeamMemberOnHover = styled.div`
  flex-direction: column;
  width: 100%;
  display: flex;
  visibility: hidden;
  opacity: 0;
  background-color: ${Colors.seafoamLite};
  transition: all 0.5s ease-out;
  position: absolute;
  height: 100%;

  ${PersonalInfo} {
    color: ${Colors.charcoal};
    background-color: ${Colors.seafoamLite};
  }
`;

const TeamMemberContainer = styled.div`
  max-width: 355px;
  min-width: 150px;
  flex: 0 1 50%;
  transition: all 0.5s ease-out;
  display: flex;
  margin: 0 15px 64px;
  cursor: pointer;
  position: relative;

  :hover {
    ${TeamMember} {
      opacity: 0;
      visibility: hidden;
    }

    ${TeamMemberOnHover} {
      opacity: 1;
      visibility: visible;
    }
  }

  @media (max-width: ${Screens.lg}) {
    margin-bottom: 50px;
  }

  @media (max-width: ${Screens.xs}) {
    flex: 0 1 100%;
  }
`;

const TeamPhotoContainer = styled.div`
  width: 100%;
  max-height: 720px;
  background: ${Colors.beachTealLighter};
  overflow: hidden;
`;

const TeamImage = styled(Image)`
  img {
    left: 50% !important;
    transform: translate(-50%) !important;
  }
`;

export {
  StyledWrapper,
  Container,
  TeamContainer,
  TeamMemberContainer,
  TeamMember,
  TeamMemberOnHover,
  PersonalInfo,
  PersonalName,
  PersonalPosition,
  CommentContainer,
  QuotationMark,
  Comment,
  TeamImage,
  TeamPhotoContainer,
};
