import React, { useState } from "react";
import ArrowCollapsible from "@svg-icons/arrow-collapsible";
import {
  Container,
  Item,
  Button,
  Content,
  CollapseHeader,
  CollapseHeaderTitle,
  ArrowContainer,
} from "./collapsible.styled";

const Collapsible = ({
  data,
  color,
  isSmallItems,
  setActiveItem = () => {},
}) => {
  const [openedId, setOpenedId] = useState(null);

  const onClickTrigger = id => {
    setOpenedId(id === openedId ? null : id);
    setActiveItem(id === openedId ? null : id);
  };

  return (
    <Container>
      {data.map(({ id, title, content, labelColor }) => (
        <Item key={id} borderColor={color} isSmallItems={isSmallItems}>
          <Button onClick={() => onClickTrigger(id)}>
            <CollapseHeader labelColor={labelColor}>
              <CollapseHeaderTitle>{title}</CollapseHeaderTitle>
            </CollapseHeader>
            <ArrowContainer isDown={openedId === id}>
              <ArrowCollapsible color={color} />
            </ArrowContainer>
          </Button>
          <Content className={openedId === id ? "visible" : ""}>
            {content}
          </Content>
        </Item>
      ))}
    </Container>
  );
};

export default Collapsible;
