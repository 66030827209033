import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Wrapper } from "@components/shared.styled";
import PersonCard from "@components/person-card";
import convertMarkdownToHtml from "@helpers/convert-markdown-to-html";
import {
  Container,
  Content,
  Title,
  InfoBlock,
  Comment,
  StyledQuote,
  CommentBody,
  Sign,
} from "./why-founded.shared";

const WhyFounded = () => {
  const {
    markdownRemark: {
      frontmatter: {
        whyFounded: {
          backgroundColor,
          largeHeader,
          comment,
          signature,
          author,
        },
      },
    },
  } = useStaticQuery(
    graphql`
      {
        markdownRemark(fileAbsolutePath: { regex: "/company/" }) {
          frontmatter {
            whyFounded {
              author {
                name
                position
                photo {
                  publicURL
                  childImageSharp {
                    fixed(width: 78, quality: 100) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              backgroundColor
              comment
              signature
              largeHeader
            }
          }
        }
      }
    `
  );

  return (
    <Container id="about" backgroundColor={backgroundColor}>
      <Wrapper>
        <Content>
          <Title>{largeHeader}</Title>
          <InfoBlock>
            <Comment>
              <StyledQuote>“</StyledQuote>
              <CommentBody
                dangerouslySetInnerHTML={{
                  __html: convertMarkdownToHtml(comment),
                }}
              />
            </Comment>
            <Sign>{signature}</Sign>
            <PersonCard person={author} />
          </InfoBlock>
        </Content>
      </Wrapper>
    </Container>
  );
};

export default WhyFounded;
