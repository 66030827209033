import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import * as Colors from "@constants/colors";
import {
  Wrapper,
  BlockSupTitle,
  BlockDescription,
} from "@components/shared.styled";
import Collapsible from "@components/collapsible";
import convertMarkdownToHtml from "@helpers/convert-markdown-to-html";
import {
  Container,
  Content,
  InfoBlock,
  StyledBlockTitle,
  CollapseContainer,
  PositionContent,
  PositionDepartment,
  PositionDescription,
  PositionButtonContainer,
  PositionButton,
} from "./join-team.styled";

const JoinTeam = () => {
  const {
    companyPageData: {
      frontmatter: {
        joinTeam: { backgroundColor, smallHeader, largeHeader, description },
      },
    },
    positions,
  } = useStaticQuery(
    graphql`
      {
        companyPageData: markdownRemark(
          fileAbsolutePath: { regex: "/company/" }
        ) {
          frontmatter {
            joinTeam {
              backgroundColor
              smallHeader
              largeHeader
              description
            }
          }
        }
        positions: allMarkdownRemark(
          filter: { fileAbsolutePath: { regex: "/positions/" } }
        ) {
          nodes {
            frontmatter {
              slug
              title
              location
              summary
              buttonLabel
            }
          }
        }
      }
    `
  );

  const getUpdatedPositions = () =>
    positions.nodes.map(
      (
        { frontmatter: { slug, buttonLabel, title, location, summary } },
        index
      ) => ({
        id: index,
        title,
        content: (
          <PositionContent>
            <PositionDepartment>{location}</PositionDepartment>
            <PositionDescription
              dangerouslySetInnerHTML={{
                __html: convertMarkdownToHtml(summary),
              }}
            />
            <PositionButtonContainer>
              <PositionButton to={`/careers/${slug}`}>
                {buttonLabel}
              </PositionButton>
            </PositionButtonContainer>
          </PositionContent>
        ),
      })
    );

  return (
    <Container id="careers" backgroundColor={backgroundColor}>
      <Wrapper>
        <Content>
          <InfoBlock>
            {smallHeader && <BlockSupTitle>{smallHeader}</BlockSupTitle>}
            <StyledBlockTitle>{largeHeader}</StyledBlockTitle>
            <BlockDescription
              dangerouslySetInnerHTML={{
                __html: convertMarkdownToHtml(description),
              }}
            />
          </InfoBlock>
          <CollapseContainer>
            <Collapsible
              data={getUpdatedPositions()}
              color={Colors.charcoal}
              isSmallItems
            />
          </CollapseContainer>
        </Content>
      </Wrapper>
    </Container>
  );
};

export default JoinTeam;
