import styled from "styled-components";
import * as Colors from "@constants/colors";

const Container = styled.div`
  display: flex;
`;

const PhotoContainer = styled.div`
  border-radius: 50%;
  width: 78px;
  height: 78px;
  margin-right: 18px;
  flex-shrink: 0;
`;

const PersonalInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: ${({ textColor = Colors.offWhite }) => textColor};
  line-height: 24px;
  text-align: left;
  max-width: 340px;
`;

const PersonName = styled.h3`
  margin: 0 0 2px;
  font-weight: bold;
`;

const PersonDescription = styled.p`
  margin: 2px 0 0;
`;

export {
  Container,
  PhotoContainer,
  PersonalInfo,
  PersonName,
  PersonDescription,
};
