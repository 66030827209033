import styled from "styled-components";
import * as Colors from "@constants/colors";
import { Screens } from "@constants/screens";
import { Link } from "gatsby";
import { BlockTitle } from "@components/shared.styled";

const Container = styled.section`
  padding: 112px 0 112px;
  background: ${({ backgroundColor = Colors.offWhite }) => backgroundColor};

  @media (max-width: ${Screens.lg}) {
    padding: 50px 0;
  }
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: ${Screens.md}) {
    flex-wrap: wrap;
  }
`;

const InfoBlock = styled.div`
  max-width: 463px;
  margin-right: 20px;
`;

const StyledBlockTitle = styled(BlockTitle)`
  margin: 0 0 26px;
`;

const CollapseContainer = styled.div`
  color: ${Colors.charcoal};
  width: 538px;

  button {
    color: ${Colors.charcoal};
  }
`;

const PositionContent = styled.div`
  font-size: 20px;
`;

const PositionDepartment = styled.p`
  margin: 5px 0 35px;
`;

const PositionDescription = styled.div`
  font-size: 18px;
  margin: 0 0 30px;
  line-height: 28px;
`;

const PositionButtonContainer = styled.div`
  display: flex;
  margin: 0 0 30px;
`;

const PositionButton = styled(Link)`
  background: ${Colors.seafoamLite};
  color: ${Colors.charcoal};
  font-size: 18px;
  font-weight: bold;
  line-height: 49px;
  text-decoration: none;
  display: flex;
  padding: 0 35px;
`;

export {
  Container,
  Content,
  InfoBlock,
  StyledBlockTitle,
  CollapseContainer,
  PositionContent,
  PositionDepartment,
  PositionDescription,
  PositionButtonContainer,
  PositionButton,
};
