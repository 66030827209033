import React, { useState, useCallback } from "react";
import {
  Wrapper,
  TableWrapper,
  InfoTable,
  TableHeaderCell,
  TableRow,
  TableCell,
  InfoCardsBlocks,
  Card,
  CardItem,
} from "@components/shared.styled";
import formatDate from "@helpers/format-date";
import {
  Container,
  StyledBlockTitle,
  ButtonContainer,
  StyledViewMoreButton,
} from "./in-news.styled";
import { graphql, useStaticQuery, withPrefix } from "gatsby";

const staticTableHeadersProps = [
  {
    width: "162px",
  },
  {},
  {
    width: "173px",
    textAlign: "right",
  },
];

const InNews = () => {
  const {
    markdownRemark: {
      frontmatter: { inNewsBlock },
    },
    paginatedCollectionPage,
  } = useStaticQuery(
    graphql`
      {
        paginatedCollectionPage(
          collection: { name: { eq: "in-news" } }
          index: { eq: 0 }
        ) {
          nodes
          hasNextPage
          nextPage {
            id
          }
          collection {
            id
          }
        }
        markdownRemark(fileAbsolutePath: { regex: "/company/" }) {
          frontmatter {
            inNewsBlock {
              largeHeader
              display
              headers {
                title
              }
            }
          }
        }
      }
    `
  );
  const { largeHeader, display, headers } = inNewsBlock;
  const tableHeaders = staticTableHeadersProps.map((item, index) => ({
    ...item,
    name: headers[index].title,
  }));
  const initialPage = paginatedCollectionPage;
  const collectionId = initialPage.collection.id;
  const [latestPage, setLatestPage] = useState(initialPage);
  const [inNewsItems, setInNewsItems] = useState(initialPage.nodes);

  const loadNextPage = useCallback(async () => {
    if (!latestPage.hasNextPage) return;

    const nextPageId = latestPage.nextPage.id;
    const path = withPrefix(
      `/paginated-in-news-data/${collectionId}/${nextPageId}.json`
    );

    const res = await fetch(path);
    const json = await res.json();

    setInNewsItems(state => [...state, ...json.nodes]);
    setLatestPage(json);
  }, [latestPage, collectionId]);

  const onRowClick = url => {
    window.open(url, "_blank");
  };

  return (
    <>
      {display && (
        <Container id="news">
          <Wrapper>
            <StyledBlockTitle>{largeHeader}</StyledBlockTitle>
            <TableWrapper>
              <InfoTable>
                <thead>
                  <tr>
                    {tableHeaders.map(({ width, textAlign, name }) => (
                      <TableHeaderCell
                        key={name}
                        width={width}
                        textAlign={textAlign}
                      >
                        {name}
                      </TableHeaderCell>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {inNewsItems.map(
                    ({ publication, title, date, url }, index) => (
                      <TableRow key={index} onClick={() => onRowClick(url)}>
                        <TableCell>{publication}</TableCell>
                        <TableCell>{title}</TableCell>
                        <TableCell textAlign="right">
                          {formatDate(date)}
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </tbody>
              </InfoTable>
              <InfoCardsBlocks>
                {inNewsItems.map(({ publication, title, date, url }, index) => (
                  <Card key={index} onClick={() => onRowClick(url)}>
                    <CardItem>{publication}</CardItem>
                    <CardItem>{title}</CardItem>
                    <CardItem>{formatDate(date)}</CardItem>
                  </Card>
                ))}
              </InfoCardsBlocks>
            </TableWrapper>
            {latestPage.hasNextPage && (
              <ButtonContainer>
                <StyledViewMoreButton onClick={loadNextPage}>
                  + View More
                </StyledViewMoreButton>
              </ButtonContainer>
            )}
          </Wrapper>
        </Container>
      )}
    </>
  );
};

export default InNews;
