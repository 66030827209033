import React from "react";
import * as Colors from "@constants/colors";

const ArrowCollapsible = ({
  width = 18,
  height = 18,
  color = Colors.offWhite,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 24.403 24.403"
  >
    <path
      d="M0,0H16.013V16.5"
      transform="translate(0.707 12.737) rotate(-45)"
      fill="none"
      stroke={color}
      strokeWidth="2"
    />
  </svg>
);

export default ArrowCollapsible;
