import React from "react";
import Image from "@components/image";
import {
  Container,
  PhotoContainer,
  PersonalInfo,
  PersonName,
  PersonDescription,
} from "./person-card.styled";

const PersonCard = ({ person }) => {
  const { name, position, description, photo, textColor } = person;

  return (
    <Container>
      {photo && (
        <PhotoContainer>
          <Image image={photo} isFixed width="100%" />
        </PhotoContainer>
      )}
      <PersonalInfo textColor={textColor}>
        <PersonName>{name}</PersonName>
        {position && <PersonDescription>{position}</PersonDescription>}
        {description && <PersonDescription>{description}</PersonDescription>}
      </PersonalInfo>
    </Container>
  );
};

export default PersonCard;
