import React, { useEffect, useRef } from "react";
import { graphql, useStaticQuery } from "gatsby";
import Image from "@components/image";
import { BlockSupTitle, BlockTitle } from "@components/shared.styled";
import convertMarkdownToHtml from "@helpers/convert-markdown-to-html";
import { applyParallaxPhoto } from "@helpers/animations";
import {
  StyledWrapper,
  Container,
  TeamContainer,
  TeamMemberContainer,
  TeamMember,
  TeamMemberOnHover,
  PersonalInfo,
  PersonalName,
  PersonalPosition,
  CommentContainer,
  QuotationMark,
  Comment,
  TeamImage,
  TeamPhotoContainer,
} from "./team.styled";

const Team = () => {
  const {
    markdownRemark: {
      frontmatter: {
        team: {
          backgroundColor,
          smallHeader,
          largeHeader,
          teamMembers,
          imageUrl,
          alt,
        },
      },
    },
  } = useStaticQuery(
    graphql`
      {
        markdownRemark(fileAbsolutePath: { regex: "/company/" }) {
          frontmatter {
            team {
              backgroundColor
              smallHeader
              largeHeader
              teamMembers {
                name
                position
                photo {
                  publicURL
                  childImageSharp {
                    fluid(maxWidth: 600, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                comment
              }
              imageUrl {
                publicURL
                childImageSharp {
                  fluid(maxWidth: 1200, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              alt
            }
          }
        }
      }
    `
  );

  const teamPhotoContainerRef = useRef(null);
  const teamPhotoRef = useRef(null);

  useEffect(() => {
    // apply parallax image
    const wrapperEl = teamPhotoContainerRef.current;
    const imageEl = teamPhotoRef.current;
    const { timeline, scrollTriggerInstance } = applyParallaxPhoto(
      wrapperEl,
      imageEl
    );

    // destroy animations on unmount
    return () => {
      timeline.pause(0).kill();
      scrollTriggerInstance.kill(true);
    };
  });

  return (
    <>
      <Container id="leadership" backgroundColor={backgroundColor}>
        <StyledWrapper>
          {smallHeader && <BlockSupTitle>{smallHeader}</BlockSupTitle>}
          <BlockTitle>{largeHeader}</BlockTitle>
          <TeamContainer>
            {teamMembers.map(({ name, position, photo, comment }) => (
              <TeamMemberContainer key={name}>
                <TeamMember>
                  <TeamImage image={photo} height="522px" />
                  <PersonalInfo>
                    <PersonalName>{name}</PersonalName>
                    <PersonalPosition>{position}</PersonalPosition>
                  </PersonalInfo>
                </TeamMember>
                <TeamMemberOnHover>
                  <CommentContainer>
                    <QuotationMark>“</QuotationMark>
                    <Comment
                      dangerouslySetInnerHTML={{
                        __html: convertMarkdownToHtml(comment),
                      }}
                    />
                  </CommentContainer>
                  <PersonalInfo>
                    <PersonalName>{name}</PersonalName>
                    <PersonalPosition>{position}</PersonalPosition>
                  </PersonalInfo>
                </TeamMemberOnHover>
              </TeamMemberContainer>
            ))}
          </TeamContainer>
        </StyledWrapper>
      </Container>
      <TeamPhotoContainer ref={teamPhotoContainerRef}>
        <div ref={teamPhotoRef}>
          <Image image={imageUrl} alt={alt} width="100%" />
        </div>
      </TeamPhotoContainer>
    </>
  );
};

export default Team;
