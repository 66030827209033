import React, { useEffect, createRef } from "react";
import { Helmet } from "react-helmet";
import { ScrollTrigger } from "gsap/all";
import { graphql, useStaticQuery } from "gatsby";
import { applyFadeInAnimation } from "@helpers/animations";
import GetStarted from "@modules/get-started";
import Layout from "@components/layout";
import WhyFounded from "@modules/why-founded";
import Team from "@modules/team";
import JoinTeam from "@modules/join-team";
import InNews from "@modules/in-news";
import UpcomingEvents from "@modules/upcoming-events";

const Company = () => {
  const {
    markdownRemark: {
      frontmatter: { getStarted, pageName },
    },
  } = useStaticQuery(
    graphql`
      {
        markdownRemark(fileAbsolutePath: { regex: "/company/" }) {
          frontmatter {
            getStarted {
              backgroundColor
              blockBackground
              buttonLabel
              message
            }
            pageName
          }
        }
      }
    `
  );

  const modules = [
    <WhyFounded />,
    <Team />,
    <JoinTeam />,
    <InNews />,
    <UpcomingEvents />,
  ];
  const moduleRefs = modules.map(() => createRef());

  useEffect(() => {
    moduleRefs.forEach(moduleRef => applyFadeInAnimation(moduleRef));
    return () => ScrollTrigger.getAll().forEach(t => t.kill());
  });

  return (
    <Layout>
      <Helmet>
        <title>{pageName}</title>
      </Helmet>
      {modules.map((module, index) => (
        <div ref={moduleRefs[index]} key={index}>
          {module}
        </div>
      ))}
      <GetStarted {...getStarted} />
    </Layout>
  );
};

export default Company;
