import styled from "styled-components";
import * as Colors from "@constants/colors";
import { Screens } from "@constants/screens";

const Container = styled.div`
  .visible {
    transition: max-height 0.5s cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    max-height: 1500px;
    overflow: auto;
  }
`;

const Item = styled.div`
  border-bottom: 1px solid ${({ borderColor = Colors.gray }) => borderColor};
  padding: ${({ isSmallItems }) =>
    isSmallItems ? "20px 0 10px" : "34px 0 30px"};

  :last-of-type {
    border: none;
  }

  @media (max-width: ${Screens.md}) {
    padding: 28px 0;
  }
`;

const Button = styled.button`
  background-color: transparent;
  cursor: pointer;
  border: none;
  outline: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0;
`;

const Content = styled.div`
  transition: max-height 0.5s cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  max-height: 0;
  overflow: hidden;
`;

const CollapseHeader = styled.div`
  position: relative;

  ${({ labelColor }) =>
    labelColor
      ? `
    :before {
      content: '';
      display: block;
      height: 4px;
      width: 64px;
      border-radius: 2px;
      margin-bottom: 12px;
      background: ${labelColor};
    }
  `
      : ""}
`;

const CollapseHeaderTitle = styled.p`
  margin: 0;
  font-size: 22px;
  font-weight: 600;
  padding: 0;

  @media (max-width: ${Screens.lg}) {
    font-size: 20px;
  }
`;

const ArrowContainer = styled.div`
  transform: rotate(90deg);
  transition: all 0.3s;
  padding-top: 12px;

  ${({ isDown }) => (isDown ? `transform: rotate(180deg);` : "")}
`;

export {
  Container,
  Item,
  Button,
  Content,
  CollapseHeader,
  CollapseHeaderTitle,
  ArrowContainer,
};
