import styled from "styled-components";
import { Screens } from "@constants/screens";
import { BlockTitle, ViewMoreButton } from "@components/shared.styled";

const Container = styled.section`
  padding: 112px 0;

  @media (max-width: ${Screens.lg}) {
    padding: 50px 0;
  }
`;

const StyledBlockTitle = styled(BlockTitle)`
  margin: 0 0 64px;

  @media (max-width: ${Screens.md}) {
    margin-bottom: 30px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledViewMoreButton = styled(ViewMoreButton)`
  margin: 0;
`;

export { Container, StyledBlockTitle, ButtonContainer, StyledViewMoreButton };
